import React, { useEffect, useState } from 'react';
import '../utils/styles.css';

export default function Modal({ username, modal, closeModal }) {
  const name = username;

  return (
    <>
      {modal && (
        <div className="modal">
          <div onClick={closeModal} className="overlay"></div>
          <div className="modal-content">
            <h2>Hello Sir, {name} </h2>
            <p>
              Welcome to BIJOY IT, your application has been submitted
              successfully. You'll be contacted very soon.
            </p>
            <p>Thank you</p>
            <button className="close-modal" onClick={() => closeModal()}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
