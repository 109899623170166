export const static_address = [
  {
    name: 'Mymensingh City',
    police_stations: [
      {
        name: 'Ward No: 01',
        union: [
          'খাগডহর (পূর্বতন পৌরসভার ১নং ওয়ার্ডের অংশ)',
          'গলগন্ডা (পূর্বতন পৌরসভার ১নং ওয়ার্ডের অংশ)',
          'ঢোলাদিয়া',
        ],
      },
      {
        name: 'Ward No: 02',
        union: [
          'ইটাখোলা রোড (পূর্বতন পৌরসভার ২নং ওয়ার্ডের অংশ)',
          'কাশর ',
          'গলগন্ডা (পূর্বতন পৌরসভার ২নং ওয়ার্ডের অংশ) ',
          'গোলাপজান রোড (পূর্বতন পৌরসভার ২নং ওয়ার্ডের অংশ)',
          'মেহগনি রোড (পূর্বতন পৌরসভার ২নং ওয়ার্ডের অংশ) ',
        ],
      },
      {
        name: 'Ward No: 03',
        union: [
          'ইটাখোলা রোড (পূর্বতন পৌরসভার ৩নং ওয়ার্ডের অংশ)',
          'কলেজ রোড/কাঁচিঝুলি ',
          'কে. সি রায় রোড ',
          'কে. সি রায় রোড/জেল রোড ',
          'গোলাপজান রোড পূর্ব ',
          'কাঁচিঝুলি মসজিদ রোড ',
          'কাঁচিঝুলি মসজিদ রোড ',
          'মেহগনি রোড (পূর্বতন পৌরসভার ৩নং ওয়ার্ডের অংশ) ',
          'সাহেব কোয়ার্টার ',
          'হামিদ উদ্দিন রোড',
        ],
      },
      {
        name: 'Ward No: 04',
        union: [
          'গোহাইল কান্দি পশ্চিম ',
          'তিনকোনা পুকুর পাড়',
          'প্রভাতী সেনা লেন সানকিপাড়া ',
          'মাজার শরীফ রোড ',
          'সানকিপাড়া রোড (দক্ষিণ)',
        ],
      },
      {
        name: 'Ward No: 05',
        union: [
          'কলেজ রোড ',
          'গুলকিবাড়ী',
          'গোহাইলকান্দি পূর্ব ',
          'জিলা স্কুল রোড ',
          'মোহাম্মদ আলী রোড ',
          'শ্যামাচারণ রায় রোড ',
          'সানকিপাড়া উত্তর ',
        ],
      },
      {
        name: 'Ward No: 06',
        union: [
          'আকুয়া উত্তরপাড়া',
          'আকুয়া মাদ্রাসা কোয়ার্টার ',
          'এস. এ. সরকার রোড',
          'নাসিরাবাদ কলেজ রোড',
          'আকুয়া চৌরাঙ্গীর মোড় ',
          'আকুয়া ফুলবাড়ীয়া রোড ',
          'মাজার শরীফ রোড সানকিপাড়া ',
          'আকুয়া লিচু বাগান ',
          'সানকিপাড়া বাজার দক্ষিণ চালপট্টি ',
          'সেনবাড়ী রোড পশ্চিম ',
          'সেনবাড়ী রোড পূর্ব ',
          'হামেদ আলী রোড ',
        ],
      },
      {
        name: 'Ward No: 07',
        union: [
          'অতুল চক্রবর্তী রোড ',
          'অমৃত বাবু রোড ',
          'আবুল মনসুর সড়ক ',
          'ঈশান চক্রবর্তী রোড',
          'কাচারী রোড ',
          'কালী শংকর গুহ রোড ',
          'কে, বি, ইসমাইল রোড',
          'গংগাদাস গুহ রোড ',
          'গোবিন্দ গাঙ্গুলী রোড ',
          'টি, এন, রায় রোড (পূর্বতন পৌরসভার ৭নং ওয়ার্ডের অংশ)',
          'ডি, এন, চক্রবর্তী রোড (পূর্বতন পৌরসভার ৭নং ওয়ার্ডের অংশ) ',
          'পন্ডিত পাড়া ',
          'বিশ্বেশরী দেবী রোড ',
          'মদন বাবু রোড (পূর্বতন পৌরসভার ৭নং ওয়ার্ডের অংশ) ',
          'মৃত্যুঞ্জয়ী স্কুল রোড ',
          'রাম বাবু রোড (পূর্বতন পৌরসভার ৭নং ওয়ার্ডের অংশ)',
          'শেরপুকুর পাড় রোড ',
          'শ্যামচারণ রায় রোড ',
          'হরি কিশোর রায় রোড ',
        ],
      },
      {
        name: 'Ward No: 08',
        union: [
          'এম, এল, সিংহ রোড',
          'এ, বি, গুহ রোড ',
          'ওল্ড পুলিশ ক্লাব রোড ',
          'ছোট বাজার ',
          'জি, কে. এম. সি. সাহা রোড ',
          'টি, এন, রায় রোড (পূর্বতন পৌরসভার ৮নং ওয়ার্ডের অংশ) ',
          'ডি, এন, চক্রবর্তী রোড (পূর্বতন পৌরসভার ৮নং ওয়ার্ডের অংশ) ',
          'থানাঘাট ও জুবলী ঘাট ',
          'দূর্গাবাড়ী রোড ',
          'নিউ মার্কেট ',
          'বাসাবাড়ী রোড ',
          'বড় বাজার ',
          'মদন বাবু রোড ',
          'মেছুয়া বাজার ',
          'রমেশ সেন রোড ',
          'রাম বাবু রোড (পূর্বতন পৌরসভার ০৮নং ওয়ার্ডের অংশ)',
          'শাখারী পট্টি ',
          'স্টেশন রোড ',
          'স্বদেশী বাজার ',
        ],
      },
      {
        name: 'Ward No: 09',
        union: [
          'এস, কে, মহারাজা রোড ',
          'কালী বাড়ী রোড ',
          'কালী বাড়ী কবরখানা ',
          'কালী বাড়ী বাইলেন ',
          'গগন চৌধুরী বাইলেন ',
          'গোলকপুর লজ ',
          'জহির উদ্দিন রোড ',
          'জে, সি, গুহ রোড ',
          'টিক্কাপাড়া বাইলেন ',
          'বড় মসজিদ রোড ',
          'মহারাজা রোড ',
          'মহারাজা বাইলেন ',
        ],
      },
      {
        name: 'Ward No: 10',
        union: [
          'আকুয়া মাদ্রাসা কোয়ার্টার ',
          'গার্লস স্কুল রোড ',
          'জমির মুন্সী লেন ',
          'গিরিশ চক্রবর্তী রোড ',
          'জিলা স্কুল রোড ',
          'নতুন বাজার কসাই পট্টি ',
          'নাহা রোড ',
          'পঁচা পুকুর পাড় ',
          'বাউন্ডারী রোড ',
          'মাকরজানি লেন (পূর্বতন পৌরসভার ১০ নং ওয়ার্ডের অংশ) ',
          'রাম বাবু রোড ',
          'শ্যামাচারণ রায় রোড ',
          'সাহেব আলী রোড ',
          'সারদা ঘোষ রোড (পূর্বতন পৌরসভার ১০ নং ওয়ার্ডের অংশ) ',
          'সি, কে, ঘোষ রোড ',
          'হরিজন পল্লী (নতুন বাজার) ',
        ],
      },
      {
        name: 'Ward No: 11',
        union: [
          'নওমহল ',
          'মাকরজানি লেন (পূর্বতন পৌরসভার ১১ নং ওয়ার্ডের অংশ) ',
          'রামকৃষ্ণ মিশন রোড (পূর্বতন পৌরসভার ১১ নং ওয়ার্ডের অংশ) ',
          'সাউথ বাউন্ডারী রোড (পূর্বতন পৌরসভার ১১ নং ওয়ার্ডের অংশ) ',
          'সারদা ঘোষ রোড (পূর্বতন পৌরসভার ১১ নং ওয়ার্ডের অংশ) ',
        ],
      },
      {
        name: 'Ward No: 12',
        union: [
          'আকুয়া চৌরঙ্গীর মোড় ',
          'আকুয়া জুবলী কোয়ার্টার ',
          'আকুয়া সর্দার বাড়ী ',
          'চলিস্নশবাড়ী কলোনী ',
          'ছত্রিশবাড়ী কলোনী ',
          'গজিয়া বাড়ী রোড ',
          'রামকৃষ্ণ মিশন রোড (পূর্বতন পৌরসভার ১২ নং ওয়ার্ডের অংশ) ',
          'সাউথ বাউন্ডারী রোড (পূর্বতন পৌরসভার ১২ নং ওয়ার্ডের অংশ) ',
          'সারদা ঘোষ রোড (পূর্বতন পৌরসভার ১২ নং ওয়ার্ডের অংশ) ',
        ],
      },
      {
        name: 'Ward No: 13',
        union: [
          'আর. কে. মিশন রোড ',
          'আর. কে. মিশন রোড মোড়লপাড়া ',
          'বাঁশবাড়ী কলোনী ',
          'সেহড়া চামড়া গুদাম ',
          'সেহড়া ধোপাখোলা ',
        ],
      },
      {
        name: 'Ward No: 14',
        union: ['চরপাড়া '],
      },
      {
        name: 'Ward No: 15',
        union: ['মাসকান্দা '],
      },
      {
        name: 'Ward No: 16',
        union: [
          'এ, বি, গুহ রোড ',
          'ডি, বি, রোড আংশিক হিন্দু পলস্নী ',
          'ডি, বি, রোড পশ্চিম ও আংশিক হিন্দু পলস্নী ',
          'ডি, বি রোড পূর্ব ',
          'নাটকঘর বাইলেন ',
          'নাটকঘর লেন ',
          'পুরহিত পাড়া (পূর্বতন পৌরসভার ১৬ নং ওয়ার্ডের অংশ) ',
          'মাল গুদাম ',
          'সি, কে ঘোষ রোড ',
          'সি, কে ঘোষ রোড সংযুক্ত আর, কে মিশন ',
        ],
      },
      {
        name: 'Ward No: 17',
        union: [
          'পুরহিত পাড়া (পূর্বতন পৌরসভার ১৭ নং ওয়ার্ডের অংশ) ',
          'বাঘমারা ',
          'ব্রাহ্মপলস্নী ',
          'রেলওয়ে কোয়ার্টার ',
          'স্টেশন রোড ',
        ],
      },
      {
        name: 'Ward No: 18',
        union: [
          'পাট গুদাম আটআনী পুকুরপাড় ',
          'কালী বাড়ী রোড ',
          'জে, সি, গুহ রোড পশ্চিম ',
          'জে, সি, গুহ রোড পূর্ব ',
          'দৌলত মুন্সী লেন ',
          'পাট গুদাম মদের ডিপো ',
          'পাট গুদাম রেলীর মোড় ',
          'পাট গুদাম রেলওয়ে কলোনী ',
        ],
      },
      {
        name: 'Ward No: 19',
        union: [
          'নিউ কলোনী পাওয়ার হাউজ ',
          'পাট গুদাম দুল দুল ক্যাম্প, সার গুদাম ',
          'বলাশপুর ',
          'বলাশপুর পাল পাড়া ',
          'ভাটি কাশর ',
        ],
      },
      {
        name: 'Ward No: 20',
        union: [
          'কেওয়াট খালী ',
          'কেওয়াট খালী পশ্চিম পাড়া ',
          'কেওয়াট খালী রেলওয়ে কলোনী ',
          'পিডিবি কলোনী, কেওয়াটখালী ',
          'বলাশপুর নয়াপাড়া ',
        ],
      },
      {
        name: 'Ward No: 21',
        union: ['চকছত্রপুর ', 'বয়ড়া ভালুকা ', 'বা. কৃ. বি, আবাসিক এলাকা '],
      },
      {
        name: 'Ward No: 22',
        union: [
          'বয়ড়া (বয়রা ইউনিয়নের পূর্বতন ১, ২ ও ৩ নং ওয়ার্ডের সম্পূর্ণ অংশ) ',
        ],
      },
      {
        name: 'Ward No: 23',
        union: ['সুতিয়াখালী ', 'বেলতলী '],
      },
      {
        name: 'Ward No: 24',
        union: [
          'বয়ড়া (বয়ড়া ইউনিয়নের পূর্বতন ৭ ও ৯ নং ওয়ার্ডের সম্পূর্ণ অংশ) ',
          'ছভাগিয়া ',
          'ছালাকান্দি ',
        ],
      },
      {
        name: 'Ward No: 25',
        union: ['দিঘারকান্দা ', 'ফকিরাকান্দা '],
      },
      {
        name: 'Ward No: 26',
        union: [
          'উজান বাড়েরা (আকুয়া ইউনিয়নের পূর্বতন ৩নং ওয়ার্ডের অংশ) ',
          'কান্দাপাড়া ',
          'মধ্যবাড়েরা ',
          'ভাটিবাড়েরা ',
          'শিকারীকান্দা ',
        ],
      },
      {
        name: 'Ward No: 27',
        union: [
          'আকুয়া দক্ষিণপাড়া (আকুয়া ইউনিয়নের পূর্বতন ৫ নং ওয়ার্ডের অংশ) ',
          'মোড়লপাড়া ',
        ],
      },
      {
        name: 'Ward No: 28',
        union: [
          'চুকাইতলা ',
          'আকুয়া দক্ষিণপাড়া (আকুয়া ইউনিয়নের পূর্বতন ৪ নং ওয়ার্ডের অংশ)',
        ],
      },
      {
        name: 'Ward No: 29',
        union: [
          'উত্তর দাপুনিয়া (দাপুনিয়া ইউনিয়নের পূর্বতন ৭ নং ',
          'ওয়ার্ডের অংশ) ',
          'কলাপাড়া (দাপুনিয়া ইউনিয়নের পূর্বতন ৭ নং ওয়ার্ডের অংশ) ',
          'হাড়গুজিরপাড় ',
          'দাপুনিয়া ভাটিপাড়া (পশ্চিম) (দাপুনিয়া ইউনিয়নের পূর্বতন ৮ নং ওয়ার্ডের অংশ) ',
          'বাদেকল্পা (নাজিম) ',
          'বাদেকল্পা (রম্নসত্মম) ',
          'উজান বাড়েরা (আকুয়া ইউনিয়নের পূর্বতন ২ নং ওয়ার্ডের অংশ) ',
          'গন্দ্রপা ',
        ],
      },
      {
        name: 'Ward No: 30',
        union: [
          'খাগডহর (খাগডহর ইউনিয়নের পূর্বতন ৯ নং ওয়ার্ডের অংশ) ',
          'কিসমত ',
          'হাসিবাসি ',
          'বৈশাখাই ',
          'বাঘেরকান্দা ',
          'নিজকল্পা ',
          'রহমতপুর (খাগডহর ইউনিয়নের পূর্বতন ৩ নং ওয়ার্ডের অংশ) ',
        ],
      },
      {
        name: 'Ward No: 31',
        union: [
          'জেলখানারচর (খাগডহর ইউনিয়নের পূর্বতন ৯ নং ওয়ার্ডের অংশ) ',
          'চরঈশ্বরদিয়া ',
          'চরগোবিন্দপুর ',
        ],
      },
      {
        name: 'Ward No: 32',
        union: ['চরকালিবাড়ী '],
      },
      {
        name: 'Ward No: 33',
        union: ['চরঝাউগড়া ', 'চরগোবদিয়া ', 'রঘুরামপুর '],
      },
    ],
  },
  {
    name: 'Mymensingh',
    police_stations: [
      {
        name: 'Mymensingh Sadar',
        union: [
          'Ostodhar',
          'Burorchar',
          'Dapunia',
          'Khagdohor',
          'CharNilokshiya',
          'Kustia',
          'Porangonj',
          'Sirta',
          'CharIshwordia',
          'Ghagra',
          'Vabokhali',
        ],
      },
      {
        name: 'Fulbaria',
        union: [
          'Deukhola',
          'Naogaon',
          'Putijana',
          'Kushmile',
          'Fulbaria',
          'Bakta',
          'Rangamatia',
          'Enayetpur',
          'Kaladoho',
          'Radhakanai',
          'Asimpathuli',
          'Vobanipur',
          'Balian',
        ],
      },
      {
        name: 'Trishal',
        union: [
          'ধানীখোলা',
          'বৈলর',
          'কাঁঠাল',
          'কানিহারী',
          'ত্রিশাল',
          'হরিরামপুর',
          'সাখুয়া',
          'বালিপাড়া',
          'মোক্ষপুর',
          'মঠবাড়ী',
          'আমিরাবাড়ী',
          'রামপুর',
        ],
      },
      {
        name: 'Valuka',
        union: [
          'উথুরা',
          'মেদুয়ারী',
          'ভরাডোবা',
          'ধীতপুর',
          'ডাকাতিয়া',
          'বিরুনিয়া',
          'ভালুকা',
          'মল্লিকবাড়ী',
          'কাচিনা',
          'হবিরবাড়ী',
          'রাজৈ',
        ],
      },
      {
        name: 'Muktagacha',
        union: [
          'দুল্লা',
          'বড়গ্রাম',
          'তারাটি',
          'কুমারগাতা',
          'বাশাটি',
          'মানকোন',
          'ঘোগা',
          'দাওগাঁও',
          'কাশিমপুর',
          'খেরুয়াজানী',
        ],
      },
      {
        name: 'Dhubaura',
        union: [
          'দক্ষিণ',
          'গামারীতলা',
          'ধোবাউড়া',
          'পোড়াকান্দুলিয়া',
          'গোয়াতলা',
          'ঘোষগাঁও',
          'বাঘবেড়',
        ],
      },
      {
        name: 'Fulpur',
        union: [
          'রামভদ্রপুর',
          'ছনধরা',
          'ভাইটকান্দি',
          'সিংহেশ্বর',
          'ফুলপুর',
          'বওলা',
          'পয়ারী',
          'বালিয়া',
          'রহিমগঞ্জ',
          'রূপসী',
        ],
      },
      {
        name: 'Haluaghat',
        union: [
          'ভূবনকুড়া',
          'জুগলী',
          'কৈচাপুর',
          'হালুয়াঘাট',
          'গাজিরভিটা',
          'বিলডোরা',
          'শাকুয়াই',
          'নড়াইল',
          'ধারা',
          'ধুরাইল',
          'আমতৈল',
          'স্বদেশী',
        ],
      },
      {
        name: 'Gouripur',
        union: [
          'Sohonati',
          'Ochintopur',
          'Moylakanda',
          'Boakinagar',
          'Gouripur',
          'Maowha',
          'Ramgopalpur',
          'Douhakhola',
          'Vangnamari',
          'Sidhla',
        ],
      },
      {
        name: 'Goforgaon',
        union: [
          'রসুলপুর',
          'বারবারিয়া',
          'চরআলগী',
          'সালটিয়া',
          'রাওনা',
          'লংগাইর',
          'পাইথল',
          'গফরগাঁও',
          'যশরা',
          'মশাখালী',
          'পাঁচবাগ',
          'উস্থি',
          'দত্তেরবাজার',
          'নিগুয়ারী',
          'টাংগাব',
        ],
      },
      {
        name: 'Ishworgonj',
        union: [
          'ঈশ্বরগঞ্জ',
          'সরিষা',
          'সোহাগী',
          'আঠারবাড়ী',
          'রাজিবপুর',
          'মাইজবাগ',
          'মগটুলা',
          'জাটিয়া',
          'উচাখিলা',
          'তারুন্দিয়া',
          'বড়হিত',
        ],
      },
      {
        name: 'Nandail',
        union: [
          'চরবৈতাগৈর',
          'বীরবৈতাগের',
          'নান্দাইল',
          'চন্ডীপাশা',
          'গাংগাইল',
          'রাজগাতী',
          'মোয়াজ্জেমপুর',
          'শেরপুর',
          'সিংরইল',
          'মুশুল্লী',
          'আচারগাঁও',
          'খারুয়া',
          'জাহাঙ্গীরপুর',
        ],
      },
      {
        name: 'Tarakanda',
        union: [
          'বানিহালা',
          'বিস্কা',
          'বালিখা',
          'কাকনী',
          'ঢাকুয়া',
          'তারাকান্দা',
          'গালাগাঁও',
          'কামারগাও',
          'কামারিয়া',
          'রামপুর',
        ],
      },
    ],
  },
  {
    name: 'Netrakona',
    police_stations: [
      {
        name: 'Netrakona Sadar',
        union: [
          'চল্লিশা',
          'কাইলাটি',
          'দক্ষিণ বিশিউড়া',
          'মদনপুর',
          'আমতলা',
          'লক্ষীগঞ্জ',
          'সিংহেরবাংলা',
          'ঠাকুরাকোণা',
          'রৌহা',
          'মেদনী',
          'মৌগাতি',
          'কালিয়ারা গাবরাগাতি',
        ],
      },
      {
        name: 'Barhatta',
        union: [
          'আসমা',
          'চিরাম',
          'বাউশী',
          'বারহাট্টা',
          'রায়পুর',
          'সাহতা',
          'সিংধা',
        ],
      },
      {
        name: 'Durgapur',
        union: [
          'দূর্গাপুর',
          'কাকৈরগড়া',
          'কুল্লাগড়া',
          'চণ্ডিগড়',
          'বিরিশিরি',
          'বাকলজোড়া',
          'গাঁওকান্দিয়া',
        ],
      },
      {
        name: 'Kendua',
        union: [
          'আশুজিয়া',
          'দলপা',
          'গড়াডোবা',
          'গণ্ডা',
          'সান্দিকোনা',
          'মাসকা',
          'বলাইশিমুল',
          'নওপাড়া',
          'কান্দিউড়া',
          'চিরাং',
          'রোয়াইলবাড়ী আমতলা',
          'পাইকুড়া',
          'মোজাফরপুর',
        ],
      },
      {
        name: 'Atpara',
        union: [
          'স্বরমুশিয়া',
          'শুনই',
          'লুনেশ্বর',
          'বানিয়াজান',
          'তেলিগাতী',
          'দুওজ',
          'সুখারী',
        ],
      },
      {
        name: 'Madan',
        union: [
          'ফতেপুর',
          'নায়েকপুর',
          'তিয়শ্রী',
          'মাঘান',
          'গেবিন্দশ্রী',
          'মদন',
          'চানগাঁও',
          'কাইটাল',
        ],
      },
      {
        name: 'Khaliajhuri',
        union: [
          'কৃষ্ণপুর',
          'নগর',
          'চাকুয়া',
          'খালিয়াজুরী',
          'মেন্দিপুর',
          'গাজীপুর',
        ],
      },
      {
        name: 'Kalmakanda',
        union: [
          'কৈলাটী',
          'নাজিরপুর',
          'পোগলা',
          'কলমাকান্দা',
          'রংছাতি',
          'লেংগুরা',
          'বড়খাপন',
          'খারনৈ',
        ],
      },
      {
        name: 'Purbodhola',
        union: [
          'খলিশাউড়',
          'ঘাগড়া',
          'জারিয়া',
          'নারান্দিয়া',
          'বিশকাকুনী',
          'বৈরাটী',
          'হোগলা',
          'গোহালাকান্দা',
          'ধলামুলগাঁও',
          'আগিয়া',
          'পূর্বধলা',
        ],
      },
      {
        name: 'Mohangonj',
        union: [
          'বড়কাশিয়া বিরামপুর',
          'বড়তলী বানিহারী',
          'তেতুলিয়া',
          'মাঘান সিয়াদার',
          'সমাজ সহিলদেও',
          'সুয়াইর',
          'গাগলাজুর',
        ],
      },
    ],
  },
  {
    name: 'Jamlapur',
    police_stations: [
      {
        name: 'Jamalpur Sadar',
        union: [
          'কেন্দুয়া',
          'শরিফপুর',
          'লক্ষীরচর',
          'তুলশীরচর',
          'ইটাইল',
          'নরুন্দী',
          'ঘোড়াধাপ',
          'বাশঁচড়া',
          'রানাগাছা',
          'শ্রীপুর',
          'শাহবাজপুর',
          'তিতপল্লা',
          'মেষ্টা',
          'দিগপাইত',
          'রশিদপুর',
        ],
      },
      {
        name: 'Melandoh',
        union: [
          'দুরমুট',
          'কুলিয়া',
          'মাহমুদপুর',
          'নাংলা',
          'নয়ানগর',
          'আদ্রা',
          'চরবানী পাকুরিয়া',
          'ফুলকোচা',
          'ঘোষেরপাড়া',
          'ঝাউগড়া',
          'শ্যামপুর',
        ],
      },
      {
        name: 'Islampur',
        union: [
          'কুলকান্দি',
          'বেলগাছা',
          'চিনাডুলী',
          'সাপধরী',
          'নোয়ারপাড়া',
          'ইসলামপুর',
          'পাথশী',
          'পলবান্ধা',
          'গোয়ালেরচর',
          'গাইবান্ধা',
          'চরপুটিমারী',
          'চরগোয়ালীনি',
        ],
      },
      {
        name: 'Dewangonj',
        union: [
          'ডাংধরা',
          'চরআমখাওয়া',
          'পাররাম রামপুর',
          'হাতীভাঙ্গা',
          'বাহাদুরাবাদ',
          'চিকাজানী',
          'চুকাইবাড়ী',
          'দেওয়ানগঞ্জ',
        ],
      },
      {
        name: 'Sorishabari',
        union: [
          'সাতপোয়া',
          'পোগলদিঘা',
          'ডোয়াইল',
          'আওনা',
          'পিংনা',
          'ভাটারা',
          'কামরাবাদ',
          'মহাদান',
        ],
      },
      {
        name: 'Madargonj',
        union: [
          'চরপাকেরদহ',
          'কড়ইচড়া',
          'গুনারীতলা',
          'বালিজুড়ী',
          'জোড়খালী',
          'আদারভিটা',
          'সিধুলী',
        ],
      },
      {
        name: 'Bokshigonj',
        union: [
          'ধানুয়া',
          'বগারচর',
          'বাট্রাজোড়',
          'সাধুরপাড়া',
          'বকসীগঞ্জ',
          'নিলক্ষিয়া',
          'মেরুরচর',
        ],
      },
    ],
  },
];
