import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { static_address } from '../utils/data';
import { internetPackage } from '../utils/packages';
import '../utils/styles.css';
import toast, { Toaster } from 'react-hot-toast';
import Modal from './Modal';
import { Timestamp } from "firebase/firestore";

import RegistrationUserClass from '../network/registration.nt'
const Form = () => {
  const [userRegistration, setUserRegistration] = useState({
    username: '',
    user_phone: '',
    user_email: '',
    district: '',
    police_station: '',
    union: '',
    internet_package: '',
    address: '',
    createdAt: '',
    status: 'pending'
  });
  const [district, setDistrict] = useState('--district--');
  const [police_station, setPolice_station] = useState('--police_station--');
  const [union, setUnion] = useState('--union--');
  const [intPackage, setIntPackage] = useState('');
  const [districtData, setDistrictData] = useState([]);
  const [packageData, setPackageData] = useState([]);

  const [selectedPs, setSelectedPs] = useState([]);
  const [selectedUnion, setSelectedUnion] = useState([]);
  const [modal, setModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const form = useRef();

  useEffect(() => {
    setDistrictData(static_address);
    loadPackages();
  }, []);


  const loadPackages = async () => {
    const data = await RegistrationUserClass.getPackages()
    data.docs.map((doc) => {
      console.log("data in doc", doc.data());
      setPackageData(doc.data().package)
    })
  }

  const handleInput = e => {
    let name = e.target.name;
    let value = e.target.value;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const formData = { ...userRegistration };
    formData.district = district;
    formData.police_station = police_station;
    formData.union = union;
    formData.createdAt = Timestamp.now()

    await RegistrationUserClass.addUser(formData)
      .then((res) => {
        toast('Your request is submitted successfully', {
          style: { color: 'indianred' },
        });
        setIsSubmitted(false);
        toggleModal();
        setUserRegistration({
          username: '',
          user_phone: '',
          user_email: '',
          district: '',
          police_station: '',
          union: '',
          address: '',
          createdAt: "",
        });
        setDistrictData([]);
        setSelectedPs([]);
        setSelectedUnion([]);
      })
      .catch(err => console.log('error :', err));
    // emailjs.send('service_0olswbq','template_to9ft38',formData,'MRgVb2dOyAum68TDU')


    // emailjs.send('service_n9hu026', 'template_5huweii', formData, '64-N8hWoA4VjjIxb4')
  };


  // registered_users
  const changeDistrict = event => {
    let district = event.target.value;
    setDistrict(district);
    if (district) {
      setSelectedPs(
        districtData?.find(ctr => ctr.name === district)?.police_stations
      );
    } else {
      setSelectedPs([]);
      setSelectedUnion([]);
    }
  };

  const changePolice_stations = event => {
    let police_station = event.target.value;
    setPolice_station(police_station);
    if (police_station) {
      setSelectedUnion(
        selectedPs.find(stt => stt.name === police_station)?.union
      );
    } else {
      setSelectedUnion([]);
    }
  };

  const changeUnion = event => {
    let union = event.target.value;
    setUnion(union);
  };

  const changeIntPackage = event => {
    let packag = event.target.value;

    packageData.map((data) => {
      if (data.details == packag) {
        setUserRegistration({ ...userRegistration, internet_package: data.name });
      }
    })
    setIntPackage(packag);

    console.log("form :", userRegistration)
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const closeModal = () => {
    setModal(false);
    window.location.reload();
  };

  // if (modal) {
  //     document.body.classList.add('active-modal')
  // } else {
  //     document.body.classList.remove('active-modal')
  // }

  return (
    <div className="container">
      {!isSubmitted ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '5px 0px',
            }}
          >
            <h5 className="text-beautify header">Apply for New Connection</h5>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <form action="" ref={form} onSubmit={handleSubmit} className="form">
              <div className="user-info-block">
                <span className="name-field text-beautify">Name</span>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={userRegistration.username}
                  onChange={handleInput}
                  className="input-field"
                  placeholder="Mr./Mrs. ......"
                  required
                />
              </div>

              <div className="user-info-block">
                <span className="name-field text-beautify">Mobile</span>
                <input
                  type="phone"
                  name="user_phone"
                  id="user_phone"
                  value={userRegistration.user_phone}
                  onChange={handleInput}
                  className="input-field"
                  placeholder="01........."
                  required
                />
              </div>

              <div className="user-info-block">
                <label htmlFor="email" className="name-field text-beautify">
                  Email{' '}
                </label>
                <input
                  type="text"
                  name="user_email"
                  id="user_email"
                  value={userRegistration.user_email}
                  onChange={handleInput}
                  className="input-field"
                  placeholder="............@gmail.com"
                  required
                />
              </div>
              <br />

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="select-wrapper" style={{ width: '48%' }}>
                  <select
                    value={district}
                    className="text-beautify decorated"
                    name={district}
                    onChange={changeDistrict}
                    required
                  >
                    <option className="text-beautify" value={''}>
                      --Select your District/City--
                    </option>
                    {districtData.map(ctr => (
                      <option className="text-beautify " value={ctr.name}>
                        {ctr.name}
                      </option>
                    ))}
                  </select>
                  <br />
                </div>

                <div style={{ width: '48%' }}>
                  <select
                    value={police_station}
                    className="text-beautify decorated"
                    onChange={changePolice_stations}
                    required
                    name={police_station}
                  >
                    <option className="text-beautify" value={''}>
                      --Select your Upazila/Pourosova--
                    </option>
                    {selectedPs.map(ps => (
                      <option className="text-beautify" value={ps.name}>
                        {ps.name}
                      </option>
                    ))}
                  </select>
                  <br />
                </div>
              </div>

              <div>
                <select
                  className="text-beautify decorated"
                  value={union}
                  onChange={changeUnion}
                  required
                  name={union}
                >
                  <option className="name-field text-beautify" value={''}>
                    --Select your Union/Area--
                  </option>
                  {selectedUnion.map(cty => (
                    <option className="text-beautify" value={cty}>
                      {cty}
                    </option>
                  ))}
                </select>
              </div>

              <br />
              <div className="select-wrapper" style={{ position: 'relative' }}>
                {intPackage && (
                  <p
                    style={{ lineHeight: 0, fontSize: '12px', position: 'absolute', top: '-25px', textAlign: 'center', backgroundColor: 'red', width: '100%', fontWeight: 'bold', textTransform: 'capitalize' }}
                  >{intPackage}</p>
                )}
                <select
                  value={intPackage}
                  className="text-beautify decorated"
                  name={intPackage}
                  onChange={changeIntPackage}
                  required
                >
                  <option className="text-beautify" value={''}>
                    --Select your package--
                  </option>
                  {packageData.map(ctr => (
                    <option className="text-beautify " value={ctr.details}>
                      {ctr.name}
                    </option>
                  ))}
                </select>

              </div>
              <br />
              <div className="user-info-block">
                <span className="name-field text-beautify"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >Address</span>
                <textarea
                  name="address"
                  id="address"
                  rows="2"
                  cols="50"
                  value={userRegistration.address}
                  onChange={handleInput}
                  className="input-field text-beautify"
                  placeholder="Specify your address using your House name, Holding No, Lane etc."
                  required
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '20px',
                }}
              >
                <button
                  disabled={isSubmitted}
                  type="submit"
                  className="text-beautify"
                  style={{
                    padding: '.5em 3em',
                    borderRadius: '1em',
                    background: '#f3c7ac',
                    color: 'black',
                  }}
                >
                  SUBMIT
                </button>
              </div>
              <br />
            </form>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Submitting...</p>
        </div>
      )}
      <Modal
        username={userRegistration.username}
        modal={modal}
        closeModal={closeModal}
      />
    </div>
  );
};

export default Form;
