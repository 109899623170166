// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
const firebaseConfig = {
    apiKey: "AIzaSyA2xoiZhsEWh_cPFI9BizmOjZmBal-nsvo",
    authDomain: "ispadmin-7fb7e.firebaseapp.com",
    projectId: "ispadmin-7fb7e",
    storageBucket: "ispadmin-7fb7e.appspot.com",
    messagingSenderId: "626541345546",
    appId: "1:626541345546:web:c75cedb585d5c9942718f3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);