import { db } from "./firebaseConfig";

import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc, Timestamp } from "firebase/firestore";


const userCollectionRef = collection(db, "registered_users");
const packagesCollectionRef = collection(db, "packages");

class RegistrationUserClass {
    addUser = (user) => {
        return addDoc(userCollectionRef, user)
    }

    updateUser = (id, updatedUser) => {
        const userUpdateDoc = doc(db, "registered_users", id);
        return updateDoc(userUpdateDoc, updatedUser);
    }

    deleteUser = (id) => {
        const userDoc = doc(db, "registered_users", id);
        return deleteDoc(userDoc);
    }

    getAllUser = () => {
        return getDocs(userCollectionRef);
    }

    getPackages = () => {
        return getDocs(packagesCollectionRef);
    }

    getUser = (id) => {
        const user = doc(db, "registered_users", id);
        return getDoc(user)
    }
}

export default new RegistrationUserClass();